// Import our custom CSS
import '../scss/style.scss'
//import '../scss/menu.scss' 

import "bootstrap-icons/font/bootstrap-icons.scss"
// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

//slick
import $ from 'jquery';
import 'slick-carousel';
$("#shop-slideshow").slick()



//var tcontrols = document.querySelector('#trandslide-controls>button[data-controls="next"]');
var tcnext = $('#trandslide-controls>button[data-controls="next"]')
var tcprev = $('#trandslide-controls>button[data-controls="prev"]')

tcnext.click(function() {
    $("#trandslide").slick('slickNext');
});
tcprev.click(function() {
    $("#trandslide").slick('slickPrev');
});

var myModalSelector= document.getElementById('alertOrder');
var myModal = new bootstrap.Modal(myModalSelector);
var calcalateResult= document.getElementById('calcalateResult');


import { createApp } from 'vue';
import App from '../vue/calculate.vue';

var calc = document.getElementById('calculate');
var json =JSON.parse(calc.dataset.json) 

createApp(App,{ 
    title: calc.dataset.title,
    json: json,
    myModal:myModal,
    calcalateResult:calcalateResult
  }).mount('#calculate');