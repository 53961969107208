<template>
    <div>
      <div class="h3 py-3">{{ title  }}</div>

      <div class="row">
        <div class="col-12 col-sm-4">
          <div class=" p-3 calculate_shadow border-0 " >
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Тип недвижимости</label>
            <select class="form-select" aria-label="Default select example">
              <option v-for="item in type" :key="item.value" :label="item.label" :value="item.value" >{{ item.value }}</option> 
            </select>
          </div>
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Социальная программа</label>
            <select class="form-select" aria-label="Default select example">
              <option v-for="item in social" :key="item.value" :label="item.label" :value="item.value" >{{ item.value }}</option> 
            </select>
          </div>
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Зарплатная карта</label>
            <select class="form-select" aria-label="Default select example">
              <option v-for="item in json.bank" :key="item.value" :label="item.label" :value="item.value" >{{ item.value }}</option> 
            </select>
          </div>
          <div class="mb-3 form-check form-switch">
          <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked>
          <label class="form-check-label" for="flexSwitchCheckChecked">Есть материнский капитал</label>
        </div>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">Стоимость недвижимости</label>
          <input type="text" class="form-control" id="autoSizingInput" placeholder="Стоимость недвижимости" v-model="stoimost" >
          <input type="range" class="form-range" min="0" max="10000000" step="100000" id="customRange3" v-model="stoimost">
        </div>
        <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Первоначальный взнос</label>
            <div class="row gy-2 gx-3">
              <div class="col-6">
                <div class="input-group mb-3"> 
                <input type="text" class="form-control" id="autoSizingInput" placeholder="Jane Doe" v-model="vznos"> 
                <span class="input-group-text">₽</span>
              </div>
            </div>
            <div class="col-6">
              <div class="input-group mb-3"> 
              <input type="text" class="form-control" id="autoSizingInput" placeholder="Jane Doe" v-model="procent">
              <span class="input-group-text">%</span>
            </div>
            </div>
          </div>
          </div>
          <div class="mb-3">
          <label for="customRange3" class="form-label">Срок кредита</label>
          <input type="text" class="form-control" id="autoSizingInput" placeholder="Срок кредита" v-model="srok" >
          <input type="range" class="form-range" min="0" max="40" step="5" id="customRange3" v-model="srok">
         </div>
        </div>

        <div class=" p-3 calculate_shadow border-0 ">
          <span class="">Сумма кредита</span>
          <div class="py-3 h5">{{credit}} ₽</div>
          <div><button type="button" class="btn btn-primary" @click="sortByStavka">Хочу ипотеку</button></div>
        </div>
        </div>
        <div class="col-12 col-sm-8">
          <div class="row mb-3 p-3 calculate_shadow" v-for="(item,index) in bank.slice(0, count)" >
            <div class="col-auto"><img :src="item.src" width="60px"></div>
            <div class="col">
              <div class="card-title h6  px-2">{{ item.label }}</div>
              <div v-if="item.badge"> 
                <span class="badge fw-light me-1 text-dark" :style="'background:' + badgeColor[index] + ';'" v-for="(item2,index) in item.badge" > 
                {{ item2 }}
              </span>  
              </div> 
              <span class="fw-bold">от {{Math.round(((credit*(item.stavka*0.01))/365)*30+(((credit*(item.stavka*0.01))/365)*30)*0.05)}} ₽/мес. </span>  <br/>
              <span> от {{item.stavka }} % </span> 
              <span> до {{item.srok[1] }} лет</span>   
            </div> 
          </div>
          <div v-if="count<bank.length">
            <button type="button" class="btn btn-outline-primary w-100" @click="count=bank.length">Показать ещё</button>
          </div>
        </div>
      </div>
 
      
    </div>
  </template>
  
  <script setup>

import {
  ref,
  watch,
  computed
} from 'vue'

const props = defineProps(['title','json','myModal','calcalateResult'])
//const json = defineProps(['json'])

const count = ref(4)
const myModal = props.myModal
const calcalateResult = props.calcalateResult
const stoimost = ref(3000000)
const procent = ref(10)
const vznos = ref(300000)
const srok = ref(15)
const type = props.json.type
const bankOrigin = ref(props.json.bank)
const bank = ref(bankOrigin.value)
const social = props.json.social
const credit = ref(2700000)
const badgeColor = props.json.badgeColor

watch(procent, async (newText) => {
 vznos.value = Math.round(newText*0.01*stoimost.value)
 credit.value = stoimost.value-vznos.value
 count.value=4  
 bank.value = bankOrigin.value.filter(item => item.stavka >= newText);
})

watch(vznos, async (newText) => {
 procent.value = Math.round(newText/stoimost.value*100)
 credit.value = stoimost.value-vznos.value
 count.value=4 
 
}) 


watch(stoimost, async (newText) => {
  if(stoimost.value>10000){
    vznos.value =  Math.round(procent.value*0.01*stoimost.value)
    count.value=4 
  } 
})

watch(srok, async (newText) => {
  bank.value = bankOrigin.value.filter(item => item.srok[0] <= newText && item.srok[1] >= newText);
})

watch(credit, async (newText) => {
  bank.value = bankOrigin.value.filter(item => item.sum[0] <= newText && item.sum[1] >= newText);
})


const sortByStavka = () => {
  calcalateResult.value='Сумма кредита:'+credit.value+' Взнос:'+vznos.value+' Срок:'+vznos.value+' Стоимость:'+stoimost.value
  myModal.show() 
  console.log(credit.value)
};




  </script>
  
<style scoped> 
  
</style>
  